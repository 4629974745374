<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-5">Reliability of Experimental Measurements: Glassware Questions</h2>

      <p class="mb-n3">
        a) Which piece of glassware would be most appropriate for simply holding (or containing)
        about 75 mL of solution when accuracy is not important?
      </p>

      <v-radio-group v-model="inputs.holdingLiquid" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsHoldingLiquid"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">
        b) Which piece of glassware would be the most appropriate for transferring
        <b>exactly</b> 25.00 mL of a solution from one container to another?
      </p>

      <v-radio-group
        v-model="inputs.transferringExactAmount"
        class="ml-6 mb-0"
        :disabled="!allowEditing"
      >
        <v-radio
          v-for="option in optionsExactTransfer"
          :key="'pt-2-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">
        c) Which piece of glassware would be most appropriate for measuring out 250 mL of a
        solution?
      </p>

      <v-radio-group v-model="inputs.measuringVolume" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsMeasuringVolume"
          :key="'pt-3-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">
        d) Which piece of glassware would be most appropriate to use to make 100 mL of a solution of
        a specific concentration?
      </p>

      <v-radio-group v-model="inputs.prepareSolution" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsPrepareSolution"
          :key="'pt-4-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'OleMissGlasswareQuestions',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        holdingLiquid: null,
        transferringExactAmount: null,
        measuringVolume: null,
        prepareSolution: null,
      },
      optionsHoldingLiquid: [
        {text: '100 mL volumetric flask', value: '100mLvolFlask'},
        {text: '10 mL graduated cylinder', value: '10mLgradCyl'},
        {text: '100 mL beaker', value: '100mLbeaker'},
        {text: '50 mL volumetric/graduated pipet', value: '50mLvolPipet'},
      ],
      optionsExactTransfer: [
        {text: '25 mL volumetric/graduated pipet', value: '25mLvolPipet'},
        {text: '10 mL volumetric flask', value: '10mLvolFlask'},
        {text: '100 mL graduated cylinder', value: '100mLgradCyl'},
        {text: '250 mL beaker', value: '250mLbeaker'},
      ],
      optionsMeasuringVolume: [
        {text: '10 mL volumetric/graduated pipet', value: '10mLvolPipet'},
        {text: '250 mL graduated cylinder', value: '250mLgradCyl'},
        {text: '100 mL volumetric flask', value: '100mLvolFlask'},
        {text: '250 mL beaker', value: '250mLbeaker'},
      ],
      optionsPrepareSolution: [
        {text: '250 mL graduated cylinder', value: '250mLgradCyl'},
        {text: '100 mL beaker', value: '100mLbeaker'},
        {text: '100 mL volumetric/graduated pipet', value: '100mLvolPipet'},
        {text: '100 mL volumetric flask', value: '100mLvolFlask'},
      ],
    };
  },
};
</script>
<style scoped></style>
